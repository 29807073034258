<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<style scoped>
.frame-wrap .custom-control {
  line-height: 35px;
}
.el-form-item {
  margin-bottom: unset;
}

.f-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: unset;
}
/deep/.el-form-item__error {
  position: absolute !important;
  top: 20%;
  left: 35%;
  width: 120px;
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 21px;
  z-index: 99;
}
</style>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { regionData } from "element-china-area-data"; //省市区不带'全部'的三级联动
import { handleChange } from "@/libs/common";
import { applySend, certFee } from "@/api/grade";
import { getUserInfo } from "@/libs/common";
/**
 * 报名信息提交
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    const checkMobile = (rule, value, cb) => {
      const regMobile =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的手机号"));
    };
    return {
      title: "证书邮寄申请",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名记录",
          active: true,
        },
      ],
      options: regionData, //省市区数据
      formData: {
        xm: "",
        ssmc: "",
        ssdm: "",
        djsmc: "",
        djsdm: "",
        qxmc: "",
        qxdm: "",
        dz: "",
        yb: "",
        yddh: "",
        yjfy: "",
        zsid: "",
      },
      rules: {
        xm: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: "blur",
          },
        ],
        zsid: [
          {
            required: true,
            message: "请选择邮寄证书",
            trigger: "blur",
          },
        ],
        ssmc: [
          {
            required: true,
            message: "请选择省份",
            trigger: "blur",
          },
        ],
        djsmc: [
          {
            required: true,
            message: "请选择地级市",
            trigger: "blur",
          },
        ],
        qxmc: [
          {
            required: true,
            message: "请选择区县",
            trigger: "blur",
          },
        ],
        dz: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        yddh: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            validator: checkMobile,
            trigger: "blur",
          },
        ],
        yb: [
          {
            required: true,
            message: "请输入邮政编码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleChange(event, form, name, code) {
      handleChange(event, form, name, code);
    },
    apply() {
      this.formData.zsid = this.query.zsid;
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let data = this.formData;
          console.log(data);
          applySend(data).then((res) => {
            if (res.status) {
              this.$router.push("/recordCertificate");
            }
          });
          return false;
        }
        console.log(valid);
      });
    },
    getInfo() {
      getUserInfo().then((data) => {
        this.formData.xm = data.xm;
        // this.formData.yddh = data.yddh;
      });
    },
    back(){
      this.$router.back()
    }
  },
  created() {
    this.query = this.$route.query;
    this.query.name = window.sessionStorage.getItem("name").split(",");
    // window.sessionStorage.removeItem('name')
    // certFee().then((res) => {
    //   if (res.status) {
    //     this.formData.yjfy = res.data;
    //   }
    // });
    this.getInfo()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 650px">
          <div class="card-body">
            <h4 class="card-title border-dash pb-3">
              <img
                src="@/assets/images/person/icon/if-paper.png"
                class="mr-2"
              />如您无法自行前往领取证书，可以申请邮寄。邮寄方式为中国邮政EMS
            </h4>
            <div class="outerCotent mt-3 score-list">
              <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                class="login-form"
              >
                <div class="msgContent flexList fs-xs w-75">
                  <div class="w-100 msgLabel d-flex" style="height: 100px">
                    <div class="msgName flexList justify-content-center">
                      邮寄项目
                    </div>
                    <div class="msgVal f-column">
                      <el-form-item prop="zsid" class="f-column">
                        <div class="frame-wrap" v-for="name in query.name" :key="name">
                          {{ name }}
                        </div>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="w-100 d-flex msgLabel">
                    <div class="msgName flexList justify-content-center">
                      收件人姓名
                    </div>
                    <div class="msgVal f-column">
                      <el-form-item prop="xm" class="f-column">
                        <input
                          class="msgValInput h35 col-sm-4 form-control"
                          v-model="formData.xm"
                        />
                      </el-form-item>
                    </div>
                  </div>
                  <div class="w-100 d-flex msgLabel" style="height: 120px">
                    <div class="msgName flexList justify-content-center">
                      邮寄地址
                    </div>
                    <div
                      class="msgVal f-column"
                      style="justify-content: space-around"
                    >
                      <el-form-item prop="yjdz" class="f-column">
                        <el-cascader
                          :options="options"
                          v-model="formData.yjdz_"
                          style="width: 33.5%"
                          @change="
                            handleChange($event, formData, {
                              ssmc: 'S',
                              djsmc: 's',
                              qxmc: 'q',
                            },
                              {ssdm: 'S', djsdm: 's', qxdm: 'q' })
                          "
                        ></el-cascader>
                      </el-form-item>
                      <el-form-item prop="dz" class="f-column">
                        <input
                          class="msgValInput h35 form-control mt-3 col-sm-4 w-40"
                          style="padding-left: 5px"
                          v-model="formData.dz"
                        />
                      </el-form-item>
                    </div>
                  </div>
                  <div class="w-100 d-flex msgLabel">
                    <div class="msgName flexList justify-content-center">
                      邮政编码
                    </div>
                    <div class="msgVal f-column">
                      <el-form-item prop="yb" class="f-column">
                        <input
                          class="msgValInput h35 col-sm-4 form-control w-40"
                          v-model="formData.yb"
                        />
                      </el-form-item>
                    </div>
                  </div>
                  <div class="w-100 d-flex msgLabel">
                    <div class="msgName flexList justify-content-center">
                      联系电话
                    </div>
                    <div class="msgVal f-column">
                      <el-form-item prop="yddh" class="f-column">
                        <input
                          class="msgValInput h35 col-sm-4 form-control w-40"
                          v-model="formData.yddh"
                        />
                      </el-form-item>
                    </div>
                  </div>

                  <!-- <div class="w-100 d-flex msgLabel">
                    <div class="msgName flexList justify-content-center">
                      邮寄费用
                    </div>
                    <div class="msgVal flexList">
                      <div class="red-font font-size-16">
                        {{ formData.yjfy / 100 + "元" }}
                      </div>
                    </div>
                  </div> -->
                 
                </div>
              </el-form>
               <div class="d-flex w-100 msgLabel" style="height: 60px">
                    <div class="flexList">
                      <button
                        type="button"
                        class="btn btn-info w-md mr-3 h30"
                        @click="apply"
                      >
                        提交邮寄申请
                      </button>
                      <button type="button" class="btn btn-secondary w-sm h30" @click="back">
                        取消
                      </button>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
